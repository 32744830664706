<template>
    <a-card>
        <a-page-header
            :title='id == 0 ? "新建项目" : "修改项目"'
        />
        <a-form-model
            ref="dataForm"
            :model="dataForm"
            :rules='rules'
            v-bind='layout'
        >
            <a-form-model-item label="项目品牌" prop="principalValid" ref="principalValid">
                <a-radio-group v-model="dataForm.principalValid" v-if="id == 0" @change="onChangePrincipalType">
                    <a-radio :value="1">不限</a-radio>
                    <a-radio :value="2">
                        指定品牌
                        <a-select
                            v-model="dataForm.principalId"
                            placeholder="请选择品牌"
                            show-search
                            option-filter-prop="children"
                            @change="onChangePrincipal"
                            style="width: 300px; margin-left: 10px;"
                            :disabled="dataForm.principalValid != 2"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                                :name="item.principal_name"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-radio>
                </a-radio-group>
                <div v-else>{{ dataForm.principalName || '不限' }}</div>
            </a-form-model-item>
            <a-form-model-item label="项目预估周期" prop="pickerTime">
                <a-range-picker
                    v-model="dataForm.pickerTime"
                    value-format="YYYY-MM-DD"
                    :disabled-date="disabledDate"
                    :allow-clear="false"
                    @calendarChange="calendarChange"
                    @openChange="openChange"
                    @change="onChangeTime"
                    style="width: 100%;"
                >
                    <a-icon slot="suffixIcon" type="calendar" />
                </a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="项目总预算" prop="totalBudget">
                <a-row>
                    <a-col :span="17">
                        <a-input-number v-model="dataForm.totalBudget" :min="0" :precision="0" class="w-100" />
                    </a-col>
                    <a-col :span="1"></a-col>
                    <a-col :span="6">{{ formatUnit(dataForm.totalBudget) }}</a-col>
                </a-row>
            </a-form-model-item>
            <a-form-model-item label="项目日预算" prop="dailyBudget">
                <a-row>
                    <a-col :span="17">
                        <a-input-number v-model="dataForm.dailyBudget" :min="0" :precision="0" class="w-100" />
                    </a-col>
                    <a-col :span="1"></a-col>
                    <a-col :span="6">{{ formatUnit(dataForm.dailyBudget) }}</a-col>
                </a-row>
            </a-form-model-item>
            <a-form-model-item label="项目KPI类型" prop="kpiType">
                <a-radio-group v-model="dataForm.kpiType">
                    <a-radio :value="1">线索量</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="项目总KPI" prop="totalKpi">
                <a-input-number v-model="dataForm.totalKpi" :min="0" :precision="0" class="w-100" />
            </a-form-model-item>
            <a-form-model-item label="项目日KPI" prop="dailyKpi">
                <a-input-number v-model="dataForm.dailyKpi" :min="0" :precision="0" class="w-100" />
            </a-form-model-item>
            <a-form-model-item label="项目名称" prop="projectName" extra="项目命名规则：品牌+项目周期，例如：上汽大众20230101~20230331">
                <a-input v-model="dataForm.projectName" :maxLength="30" @change="onEditName" />
            </a-form-model-item>
            <a-form-model-item label="项目负责人">
                <a-select
                    mode="multiple"
                    v-model="dataForm.projectManager"
                    placeholder="请选择负责人"
                    allow-clear
                    show-search
                    option-filter-prop="children"
                >
                    <a-select-option
                        v-for="item in directorList"
                        :key="item.id"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="合同编码">
                <a-input v-model="dataForm.contractCode" :maxLength="30" />
            </a-form-model-item>

            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="id == 0 ? '提交' : '保存'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>

<script>
import moment from 'moment'

export default {
    data() {
        let principalValid = (rule, value, callback) => {
            if(this.dataForm.principalValid == 2 && !this.dataForm.principalId) {
                callback(new Error(`请选择品牌`))
            } else {
                callback();
            }
        }

        return {
            id: this.$route.query.id,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 13 }
            },
            dataForm: {
                principalValid: 2,
                principalId: undefined,
                principalName: '',
                pickerTime: [moment(), moment()],
                startTime: '',
                endTime: '',
                totalBudget: '',
                dailyBudget: '',
                kpiType: 1,
                totalKpi: '',
                dailyKpi: '',
                projectName: '',
                projectManager: [],
                contractCode: '',
            },
            rules: {
                principalValid: [
                    { required: true, validator: principalValid, trigger: 'change' }
                ],
                pickerTime: [
                    { type: 'array', required: true, message: '请选择项目周期', trigger: 'change' }
                ],
                totalBudget: [
                    { required: true, message: '请填写项目总预算', trigger: 'change' }
                ],
                dailyBudget: [
                    { required: true, message: '请填写项目日预算', trigger: 'change' }
                ],
                kpiType: [
                    { required: true, message: '请填写项目KPI类型', trigger: 'change' }
                ],
                totalKpi: [
                    { required: true, message: '请填写项目总KPI', trigger: 'change' }
                ],
                dailyKpi: [
                    { required: true, message: '请填写项目日KPI', trigger: 'change' }
                ],
                projectName: [
                    { required: true, message: '请填写项目名称', trigger: 'change' }
                ],

            },
            isSubmit: false,
            principalList: [],
            
            selectStartDay: '',
            isEditName: true,
            directorList: [],
            isEditName: true,
        }
    },
    created() {
        this.id != 0 ? this.getDetail() : ''
        this.getPrincipalList()
        this.getManagerList()
    },
    methods: {
        //获取品牌
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangePrincipalType() {
            if(this.dataForm.principalValid == 1) {
                this.dataForm.principalName = ''
                this.dataForm.principalId = undefined
                if(this.isEditName) {
                    this.dataForm.projectName = this.formatMissionName()
                }
            }
        },
        onChangePrincipal(val, option) {
            this.dataForm.principalName = option.data.attrs.name
            this.$refs.principalValid.onFieldChange()
            if(this.isEditName) {
                this.dataForm.projectName = this.formatMissionName()
            }
        },
        disabledDate(current) {
            return current < this.$moment(this.selectStartDay)
        },
        calendarChange(dates, dateStrings) {
            console.log(dates, dateStrings)
            this.selectStartDay = dates[0]
        },
        openChange() {
            this.selectStartDay = ''
        },
        // 计算天数
        onChangeTime(date, dateString) {
            if(this.isEditName) {
                this.dataForm.projectName = this.formatMissionName()
            }
        },
        // 单位转化为万，保留4位数
        formatUnit(value = 0) {
            let val = Number(value)
            return (val/1e4).toFixed(4) + '万'
        },
        // 编辑项目名称
        onEditName(val) {
            this.isEditName = false
        },
        // 格式化项目名称
        formatMissionName() {
            let principalName = ''
            if(this.dataForm.principalValid == 1) {
                principalName = '不限品牌'
            } else {
                principalName = this.dataForm.principalName
            }

            let time = `${moment(this.dataForm.pickerTime[0]).format('YYYYMMDD')}~${moment(this.dataForm.pickerTime[1]).format('YYYYMMDD')}`

            return `${principalName}${time}`
        },
        //获取详情
        getDetail() {
            this.$api.core.deliverManage.getProjectDetail(this.id).then((res) => {
                if(res.code == 200) {
                    this.isEditName = false

                    let _data = res.data
                    _data.projectManager = _data.projectManager ? _data.projectManager : []
                    _data.principalValid = _data.principalId ? 2 : 1

                    Object.assign(this.dataForm, _data)
                    this.dataForm.pickerTime = [moment(_data.startTime), moment(_data.endTime)]
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        //取消
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/projectManage/index'
                    })
                }
            })
        },
        //提交
        handleSubmit() {
            this.$refs.dataForm.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true
                    if(this.dataForm.principalValid == 1) {
                        this.dataForm.principalId = ''
                    }
                    this.dataForm.startTime = moment(this.dataForm.pickerTime[0]).format('YYYY-MM-DD 00:00:00')
                    this.dataForm.endTime = moment(this.dataForm.pickerTime[1]).format('YYYY-MM-DD 23:59:59')
                    
                    let params = {
                        data: this.dataForm,
                        method: 'put'
                    }

                    if(this.id == 0) {
                        params.method = 'post'
                    } else {
                        params.method = 'put'
                    }

                    this.$api.core.deliverManage.submitProjectDetail( params ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success(`操作成功`)
                            this.$router.push({
                                path: '/projectManage/index'
                            })
                        } else {
                            this.$message.error(`操作失败，${res.message}`)
                        }
                    })
                }
            })
        },
        //获取负责人
        getManagerList() {
            let params = {
                client_id: 'bfz',
                page_no: 1,
                page_size: 1000,
            }
            this.$api.core.deliverManage.getManagerList(params).then((res) => {
                if(res.code == 200) {
                    this.directorList = res.data.list
                } else {
                    this.directorList = []
                    this.$message.error(`获负责人列表失败，${res.message}`)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
</style>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
/deep/ .ant-form-item-children {
    position: relative;
}
.w-100 {
    width: 100%;
}
</style>